<template>
    <div>
        <div class="eon-remind-field">
            <div>
                <h2>{{ $t('message.login.action.eonRemindPassword') }}</h2>
            </div>

            <div v-if="sending">
                <div class="remind-sent">
                    <i class="pi pi-envelope" />
                    <p> Na adres {{ passwordRemindForm.email }} wysłaliśmy link wraz z instrukcją zmiany hasła. </p>
                </div>
                <p>Nie dotarł do ciebie e-mail?</p>
                <ul>
                    <li><i class="pi pi-check-circle" /> Zajrzyj do folderu spam</li>
                    <li><i class="pi pi-check-circle" /> Sprawdź, czy podany e-mail jest poprawny</li>
                </ul>
                <router-link :to="{name: isPublic() ? 'publicLogin' : 'login'}">
                    <EonButton class="inverted turquoise" extension="100" label="Wróć do strony logowania" />
                </router-link>
            </div>
            <div v-else>
                <VeeForm @submit.prevent="remind" v-slot="{ errors }">
                    <p> {{ $t('message.login.action.remindPasswordDetails') }} </p>
                    <EonInputText class="eon-remind-input" name="email" v-model="passwordRemindForm.email"
                                  color="turquoise" :label="$t('message.login.eonLogin')" rules="required|email"/>
                    <EonButton class="inverted turquoise eon-remind-input" :label="$t('message.login.action.send')"
                               @click="remind" :disabled="isDisabled(errors)" type="submit"/>
                </VeeForm>
            </div>
        </div>
    </div>
</template>

<script>
    import {remindPassword} from "@/swagger/vue-api-client";
    import EonInputText from "@/components/form/EonInputText";
    import EonButton from "@/components/form/EonButton";
    import {Form as VeeForm} from "vee-validate";

    export default {
        name: "EonPasswordRemindView",
        components: {
            EonInputText,
            EonButton,
            VeeForm,
        },

        data() {
            return {
                sending: false,
                passwordRemindForm: {
                    email: "",
                },
            };
        },

        methods: {
            remind() {
                this.sending = true;
                remindPassword({body: this.passwordRemindForm});
            },
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
            isPublic() {
                return window.location.href.includes("public");
            },
        },
    };
</script>

<style lang="less" scoped>
@import "assets/less/eon-remind.less";
</style>
