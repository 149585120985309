<template>
    <div>
        <a :href="href" :style="computedColor">
            <span>{{ label }}</span>
        </a>
    </div>
</template>

<script>

    export default {
        name: "EonHrefWithoutArrow",
        props: {
            href: {
                type: String,
            },
            label: {
                type: String,
            },
            color: {
                type: String,
                default: "white",
            },

        },
        computed: {
            computedColor() {
                return "color: " + this.color + ";";
            },
        },
    };
</script>

<style lang="less">

.href-button {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

.href-icon {
    fill: currentColor;
    width: 1em;
    height: 1em;
    display: inline-block;
    font-size: 1.5rem;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    -moz-user-select: none;
}

.href {
    display: flex;
    font-size: 1rem;
    align-items: center;
}

a {
    text-decoration: none;
    font-weight: 400;

    &:hover {
        text-decoration: underline;
    }

    &:active {
        text-decoration: underline;
    }

    &:focus {
        text-decoration: underline;
    }
}

</style>
