<template>
    <Toast/>

    <EonCookies class="cookies" :buttonLabel="($t('message.cookies.accept'))">
    </EonCookies>
    <div class="main-register-field">
        <div class="eon-register-field">
            <VeeForm @submit="register" v-slot="{ errors }">
                <h2>{{ $t('message.register.register') }}</h2>
                <div class="register-inputs">
                    <EonInputText rules="required" color="turquoise" name="fullName"
                                  v-model="registerForm.fullName"
                                  :label="$t('message.register.fullName')"/>
                    <EonInputText rules="required|email" color="turquoise" name="email"
                                  v-model="registerForm.email"
                                  :label="$t('message.register.email')"/>
                    <EonPassword rules="required|validPassword" color="turquoise" name="password"
                                 v-model="registerForm.password"
                                 :label="$t('message.register.password')"/>
                </div>
                <div class="register-actions">
                    <EonButton :disabled="isDisabled(errors)" class="inverted turquoise"
                               :label="$t('message.register.action.register')"
                               type="submit"/>
                    <EonHref href="/public/login" :label="$t('message.register.action.alreadyHaveAccount')"/>

                </div>
            </VeeForm>
        </div>

        <div class="eon-info-field">
            <h2> {{ $t('message.register.info.title') }} </h2>
            <div class="bullet-box">
                <ul>
                    <li>
                        <i class="pi pi-check-circle"></i>
                        {{ $t('message.register.info.bullet1') }}
                    </li>
                    <li>
                        <i class="pi pi-check-circle"></i>
                        {{ $t('message.register.info.bullet2') }}
                    </li>
                    <li>
                        <i class="pi pi-check-circle"></i>
                        {{ $t('message.register.info.bullet3') }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import {registerPublicUser as registerRequest} from "@/swagger/vue-api-client";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonCookies from "@/components/form/EonCookies";
    import EonButton from "@/components/form/EonButton";
    import EonInputText from "@/components/form/EonInputText";
    import EonHref from "@/components/form/EonHref";
    import Toast from "primevue/toast";
    import EonPassword from "@/components/form/EonPassword";
    import {Form as VeeForm} from "vee-validate";
    import {StatusCodes} from "@/utils/StatusCodes";

    export default {
        name: "EonRegisterView",
        components: {
            EonButton,
            EonPassword,
            EonInputText,
            EonCookies,
            VeeForm,
            Toast,
            EonOutlinedButton,
            EonHref,
        },

        data() {
            return {
                registerForm: {
                    fullName: "",
                    email: "",
                    password: "",
                },
            };
        },
        methods: {
            failToast() {
                this.$toast.removeAllGroups();
                this.$toast.add({
                    // eslint-disable-next-line max-len
                    severity: "warn", summary: "Nie udało się utworzyć konta. Sprawdź, czy nie posiadasz już konta w Portalu.", life: 5000, closable: false,
                });
            },
            successToast() {
                this.$toast.removeAllGroups();
                this.$toast.add({
                    severity: "success",
                    summary: "Konto zostało utworzone. Aby się zalogować potwierdź swój adres email.",
                    life: 5000,
                    closable: false,
                });
            },
            register() {
                registerRequest({body: this.registerForm})
                    .then(() => {
                        this.successToast();
                    })
                    .catch((error) => {
                        this.failToast();
                        switch (error.response.status) {
                            case StatusCodes.SERVICE_UNAVAILABLE:
                                this.isBlocked = true;
                                this.badCredentials = false;
                                break;
                            default:
                        }
                    });
                this.registerForm = this.clearRegisterForm();
            },
            pushToLogin() {
                this.$router.push({name: "publicLogin"});
            },
            clearRegisterForm() {
                return {
                    fullName: "",
                    email: "",
                    password: "",
                };
            },
            isDisabled(errors) {
                return !(errors && Object.keys(errors).length === 0
                    && Object.getPrototypeOf(errors) === Object.prototype);
            },
        },
    };
</script>

<style lang="less">
@import "../assets/less/eon-register.less";
@import "../../components/assets/less/toast.less";

.button {
    display: flex;
    justify-content: center;
}
</style>
