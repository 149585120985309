<template>
    <div class="add-report-description">
        <h2>Sprawdź/uzupełnij adres</h2>
        <div class="add-report-details">
            <EonInputText v-model="street" label="Ulica"  rules="required|max:70"></EonInputText>
            <EonInputText v-model="residenceNumber" label="Nr domu" rules="required|max:50"></EonInputText>
            <EonInputText v-model="apartmentNumber" label="Nr mieszkania" rules="max:20"></EonInputText>
        </div>
        <div class="add-report-details">
            <EonInputText v-model="city" label="Miejscowość" rules="required|max:30"></EonInputText>
            <EonInputText v-model="postCode" label="Kod pocztowy" rules="postCode" maxlength="6"></EonInputText>
        </div>
        <h2>Dane kontaktowe</h2>
        <div class="add-report-details">
            <EonInputText v-model="name" label="Imię i nazwisko"  rules="required|max:100"></EonInputText>
            <EonInputText v-model="email" label="Adres e-mail"  rules="required|email|max:50"></EonInputText>
            <EonSelectOne name="phoneCodes" v-model="phoneCode" :items="phoneAreaCode"
                          itemLabel="dial_code" itemValue="dial_code" :emptyValue="false"
                          width="90" filter/>
            <EonInputText v-if="ifPL" v-model="phone" label="Numer telefonu"
                          rules="required|phoneNumberPL"
                          maxlength="9"></EonInputText>
            <EonInputText v-else v-model="phone" label="Numer telefonu"
                          rules="required|phoneNumber"
                          maxlength="15"></EonInputText>
        </div>
        <p>Stoen Operator Sp. z o.o. jest administratorem Twoich danych osobowych znajdujących się w
            formularzu i są one przetwarzane m. in. w celu wykonania Twojego zapytania. Informacje
            o Twoich prawach, danych kontaktowych naszych i Inspektora Ochrony Danych, podstawie
            prawnej przetwarzania, odbiorcach Twoich danych, okresie przechowywania oraz
            dobrowolności podania danych i konsekwencjach ich niepodania znajdują się
            <a href="/public/O20. OI www Formularz zgłoszenia awarii_Portal awarii_Stoen_2023_03_01.pdf">
                tutaj.
            </a></p>
        <EonCheckbox v-model="RODOConfirmation" :label="($t('message.addReport.RODO'))"></EonCheckbox>
        <RecaptchaPolicyHintView/>
        <div class="add-report-description-buttons">
            <EonOutlinedButton class="turquoise-button" label="Powrót" @click="goBack"/>
            <EonButton extension="30" class="turquoise-button"
                       label="Dalej" @click="submit" :disabled="isDisabled"/>
            <!-- <EonOutlinedButton v-if="outageInArea" class="turquoise-button"
                               label="Chcę zgłosić inną awarię"
                               extension="50" @click="redirect"/> -->
        </div>
        <OutageInAreaDialog icon="pi pi-plus" label="Dialog" dialogWidth="600" :showDialog="showDialog"
                            :isPlanned="isPlanned" :outageStart="outageStart" :outageEnd="outageEnd"
                            @sendReport="redirect" @closeReport="close" @hideDialog="hideDialog"/>
        <!-- <div class="add-report-description-buttons">
            <EonOutlinedButton class="turquoise-button" label="Powrót" @click="goBack"/>
            <EonButton extension="30" class="turquoise-button" :disabled="isDisabled"
                       label="Prześlij zgłoszenie" @click="submit"/>
        </div> -->
    </div>
</template>
<script>
    import EonInputTextArea from "@/components/form/EonInputTextArea";
    import EonButton from "@/components/form/EonButton";
    import EonOutlinedButton from "@/components/form/EonOutlinedButton";
    import EonInputText from "@/components/form/EonInputText";
    import EonInputNumber from "@/components/form/EonInputNumber";
    import EonCheckbox from "@/components/form/EonCheckbox";
    import EonSelectOne from "@/components/form/EonSelectOne";
    import {
        getPointElectricityAreaStatus as checkOutageInArea,
    } from "@/swagger/vue-api-client";
    import {TimeUtils} from "@/utils/TimeUtils";
    import countryCodes from "./CountryCodes.json";
    import OutageInAreaDialog from "./OutageInAreaDialog";
    import RecaptchaPolicyHintView from "../../static/RecaptchaPolicyHintView";

    export default {
        name: "AddUserReportDetails",
        components: {
            RecaptchaPolicyHintView,
            EonCheckbox,
            EonInputNumber,
            EonInputText,
            EonInputTextArea,
            EonButton,
            EonOutlinedButton,
            EonSelectOne,
            OutageInAreaDialog,
        },
        props: {
            googleMapsApi: null,
            report: null,
        },
        emits: ["detailsReport"],
        data() {
            return {
                street: null,
                residenceNumber: null,
                apartmentNumber: null,
                postCode: null,
                city: null,
                district: null,
                coordinates: {
                    lat: null,
                    lng: null,
                },
                name: null,
                phone: null,
                phoneCode: "+48",
                email: null,
                RODOConfirmation: false,
                submitButtonDisabled: false,
                countryCodes,
                phoneAreaCode: [],
                ifPL: true,
                outageData: {
                    start: null,
                    end: null,
                    type: "",
                },
                plannedOutages: [],
                confirmedOutages: [],
                outageInArea: false,
                showDialog: false,
                isPlanned: false,
                inExtensiveArea: false,
                extensiveAreaDescription: "",
                outageStart: "",
                outageEnd: "",
            };
        },
        beforeMount() {
            const {params} = this.$route;
            if (Object.keys(this.$route.params).length === 0) {
                this.$router.replace({name: "publicMapView"});
            }
            if (params.str) {
                this.street = params.str;
            }
            if (params.num) {
                this.residenceNumber = params.num;
            }
            if (params.post && /^[0-9]{2}-[0-9]{3}/.test(params.post)) {
                this.postCode = params.post;
            }
            if (params.city) {
                this.city = params.city;
            }
            if (params.district) {
                this.district = params.district;
            }
            if (params.outageInArea !== undefined) {
                this.outageInArea = (params.outageInArea === "true");
            }
            if (params.isPlanned !== undefined) {
                this.isPlanned = (params.isPlanned === "true");
            }
            if (params.inExtensiveArea !== undefined) {
                this.inExtensiveArea = (params.inExtensiveArea === "true");
            }
            if (params.extensiveAreaDescription) {
                this.extensiveAreaDescription = params.extensiveAreaDescription;
            }
        },
        mounted() {
            // eslint-disable-next-line
            const countryCodesWihtoutDuplicates = this.countryCodes.filter((value, index, self) => {
                // eslint-disable-next-line
                return index === self.findIndex((code) => {
                    return code.dial_code === value.dial_code;
                });
            });
            countryCodesWihtoutDuplicates.sort((a, b) => a.dial_code.replace("+", "") - b.dial_code.replace("+", ""));
            countryCodesWihtoutDuplicates.forEach((code) => {
                this.phoneAreaCode.push({name: `${code.dial_code} (${code.name})`, dial_code: code.dial_code});
            });
            if (this.phoneCode === "+48") {
                this.ifPL = true;
            } else {
                this.ifPL = false;
            }
        },
        methods: {
            coorFromAddress(googleMapsApi, address) {
                return new Promise((resolve) => {
                    if (googleMapsApi) {
                        this.geocoder = new googleMapsApi.Geocoder();
                        this.geocoder.geocode({address}, (results) => {
                            const newAddress = results[0].address_components;
                            this.district = newAddress[2].long_name;
                            this.coordinates.lat = results[0].geometry.location.lat();
                            this.coordinates.lng = results[0].geometry.location.lng();

                            resolve();
                        });
                    }
                });
            },
            // eslint-disable-next-line consistent-return
            async isOutageInArea() {
                try {
                    this.outageData = {
                        start: null,
                        end: null,
                        type: "",
                    };
                    const response = await checkOutageInArea({
                        body: {
                            lat: this.coordinates.lat,
                            lng: this.coordinates.lng,
                        },
                    });

                    this.inExtensiveArea = response.data.pointElectricityStatus === "EXTENSIVE";
                    if (this.inExtensiveArea) {
                        this.extensiveAreaDescription = response.data.description;
                    }
                    const inArea = response.data.pointElectricityStatus === "PLANNED_OUTAGE"
                        || response.data.pointElectricityStatus === "FAILURE"
                        || response.data.pointElectricityStatus === "EXTENSIVE";
                    if (response.data !== "HAS_ELECTRICITY") {
                        this.outageData.type = response.data === "PLANNED_OUTAGE" ? "PLANNED" : "CONFIRMED";
                    }
                    return inArea;
                } catch (error) {
                    console.error(error);
                }
            },
            hideDialog() {
                this.showDialog = false;
            },
            close() {
                this.$router.push({name: "publicMapView"});
            },
            goBack() {
                this.outageInArea = false;
                this.$router.replace({name: "publicAddReportLocation"});
            },
            async submit() {
                this.coorFromAddress(this.googleMapsApi, `${this.street} ${this.residenceNumber}, ${this.city}`)
                    .then(() => {
                        if (this.outageInArea === false) {
                            this.isOutageInArea()
                                .then((result) => {
                                    this.outageInArea = result;
                                    if (this.outageInArea) {
                                        this.isPlanned = this.outageData.type === "PLANNED";
                                        if (this.outageData.start !== null) {
                                            this.outageStart = this.outageData.start !== null
                                                // eslint-disable-next-line max-len
                                                ? "od " + new Date(this.outageData.start).toLocaleString("pl-PL", TimeUtils.options)
                                                : "";
                                            this.outageEnd = this.outageData.start !== null
                                                // eslint-disable-next-line max-len
                                                ? " do " + new Date(this.outageData.end).toLocaleString("pl-PL", TimeUtils.options)
                                                : "";
                                        } else {
                                            this.outageStart = "";
                                            this.outageEnd = "";
                                        }
                                        this.showDialog = true;
                                    } else {
                                        this.redirect();
                                    }
                                });
                        } else {
                            // w poprzednim kroku użytkownik już potwierdził dodanie zgłoszenia
                            // na obszarze objętym potw. awarią / planowanym wyłączeniem / rozl. awarią
                            this.redirect();
                        }
                    });
            },

            async redirect() {
                this.$emit("detailsReport", {
                    street: this.street,
                    residenceNumber: this.residenceNumber,
                    apartmentNumber: this.apartmentNumber,
                    postCode: this.postCode,
                    city: this.city,
                    district: this.district,
                    coordinates: {
                        lat: this.coordinates.lat,
                        lng: this.coordinates.lng,
                    },
                    name: this.name,
                    phone: this.phoneCode + this.phone,
                    email: this.email,
                });
                const {
                    outageInArea, isPlanned, inExtensiveArea, extensiveAreaDescription,
                } = this;
                this.$router.replace({
                    name: "publicAddReportDescription",
                    params: {
                        outageInArea, isPlanned, inExtensiveArea, extensiveAreaDescription,
                    },
                });
            },
        },
        computed: {
            isDisabled() {
                return (this.street == null || this.street.length === 0 || this.street.length > 70)
                    || this.residenceNumber == null || this.residenceNumber.length > 50
                    || (this.apartmentNumber != null && this.apartmentNumber.length > 20)
                    || (this.postCode == null || !/^[0-9]{2}-[0-9]{3}/.test(this.postCode))
                    || this.postCode.length === 0 || this.city == null || this.city.length > 30
                    || this.city.length === 0 || this.name == null || this.name.length === 0
                    || this.name.length > 100 || this.email == null || this.email.length > 50
                    || (this.phone == null || ((this.ifPL && !/^\d{9}$/.test(this.phone))
                        || (!this.ifPL && !/^\d{6,15}$/.test(this.phone)))) || (this.email == null
                        /* eslint-disable-next-line max-len */
                        || !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.email))
                    || !this.RODOConfirmation
                    || this.submitButtonDisabled;
            },
        },
        watch: {
            phoneCode(value) {
                if (value === "+48") {
                    this.ifPL = true;
                } else {
                    this.ifPL = false;
                }
            },
            report: {
                handler(newVal) {
                    this.street = newVal.street;
                    this.residenceNumber = newVal.residenceNumber;
                    this.postCode = newVal.postcode;
                    this.city = newVal.city;
                },
                deep: true,
            },
        },
    };
</script>

<style lang="less">
    #phoneCodes {
        .p-dropdown-trigger {
            width: 1.75rem;
        }
        .p-dropdown-label.p-inputtext {
            padding-left: 4px;
            padding-right: 4px;
            text-align: center;
        }
    }
    .location-message {
        .p-message-text {
            width: 100%;
            text-align: center;
        }
    }
</style>

<style lang="less" scoped>
@import "../../../assets/theme/variable.less";

h2 {
    color: @color-turquoise;
}

.add-report-description {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
    justify-content: left;
    width: 100%;
    .eon-text-area {
        width: 100%;
    }
}
.add-report-description-buttons {
    display: flex;
    justify-content: center;
    column-gap: 0.5em;
}
.add-report-details {
    display: flex;
    justify-content: center;
    column-gap: 0.5em;
    @media screen and (max-width: 600px) {
        flex-direction: column;
        gap: 10px;
    }
}

a {
    color: @color-turquoise;
    &:hover {
        color: @color-turquoise-dark;
        text-decoration: underline;
    }
    &:visited {
        color: @color-turquoise-dark;
    }
}
</style>
